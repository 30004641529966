.App {
  text-align: center;
}

body {
  margin: 0;
  color: rgba(0,0,0,.85);
  font-size: 14px;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important;
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: "tnum";
  font-style: normal !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-style: italic;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*  Add here All Custom Css */

.login-form {
  margin: 0 auto !important;
  /* Added */
  float: none !important;
  /* Added */
  margin-bottom: 10px !important;
  /* Added */
  width: 350px !important;
}

.a-remove-text-decoration {
  text-decoration: none !important;
  color: #000 !important
}

.a-rm-color {
  text-decoration: none !important;
  color: #fff !important
}

.menu-style {
  height: 100vh;
}

.profile-menu {
  float: right;
  width: 80px;
  height: 36px !important;
  padding: 0 !important;
}

.profile-menu>img {
  background-color: red;
}

.profile-media {
  height: 10px !important;
  width: 10px !important;
}

.login-form>.ant-card-head {
  background-color: #FF7540;
  color: white;
}

.login-body {
  background-image: url("./assets/images/login-form-1.png");
  /* background-color: #008173 !important; */
}

.login-button {
  background: #FF7540  !important;
  border: none !important;
}

.logo-text {
  padding: 0 0px !important;
  color: white;
  height: 64px;
  padding: 0 50px;
  line-height: 64px;
  background: #001529
}

.logo {
  height: 32px;
  margin: 16px;
  color: #fff;
  /* background: rgba(255, 255, 255, 0.3); */
}



.menu-style-custome {
  background-image: linear-gradient(144deg, #fa3881, #5b316b 50%, #391464) !important;
  color: #fff !important;
}

.menu-style-custome:hover {
  background-image: linear-gradient(144deg, #391464, #5b316b 50%, #fa3881) !important;
  color: #fff !important;
}

.scroll-off-fix {
  height: 600px;
  overflow-y: scroll;
  position: fixed;
  width: 200px !important;
}


.profile-menu {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: transparent !important;
  margin-top: 7px;
}

.profile-menu .ant-menu-submenu {
  opacity: 1 !important;
  height: 50px !important;
  width: 50px !important;
  order: 1;
  padding: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.profile-img-icon {
  width: 40px;
  border-radius: 50%;
  margin: -11px 0 0 0;
}

.ant-menu-submenu .ant-image-img {
  border-radius: 50%;
}

.ant-layout-header.header {
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 98;
}

aside.ant-layout-sider {
  position: fixed;
  left: 0;
  z-index: 99;
}

.ant-layout-content {
  padding-left: 200px;
}

.site-layout-background {
  padding-left: 220px;
  padding-top: 60px;
}

.my-header-color {
  background: linear-gradient(90deg, #ff7540 0%, #a1c4de 100%) !important;
  color: #fff !important;
}

.my-sider-color,
.ant-layout-sider-trigger {
  background: #ff7540 !important;
  color: #fff !important;
}

.my-sidermenu-color,
.ant-layout-sider-trigger {
  background: #ef825a !important;
  color: #fff !important;
}

.my-sider-color>.ant-menu-item a,
.my-sider-color>.ant-menu-item-selected {
  color: #fff !important;
}

.ant-menu-item-selected {
  background: linear-gradient(90deg, #a1c4de 0%, #ff7540 100%) !important;
}

.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu-item:hover,
/* .ant-menu-light .ant-menu-submenu-active, */
/* .ant-menu-light .ant-menu-submenu-title:hover, */
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: #fff !important;
  background: linear-gradient(90deg, #ff7540 0%, #a1c4de 100%) !important;
}

.my-theme-color-button>button,
.my-theme-color-button-fa {
  color: #fff !important;
  background: linear-gradient(90deg, #ff7540 0%, #a1c4de 100%) !important;
  margin: 2px;
  border: none;
}

.my-submit-button,
.my-reset-button {
  color: #fff !important;
  background: #ff7540 !important;
  border: none !important;
  margin: 3px;
}

.profile-menu>.ant-menu {
  background: none;
}

.profile-menu>.ant-menu-horizontal {
  border: none;
}